import "./style.css"
const AboutMeAppContentEductionCoursesTable = ({courses}) => {

    const parse_course = (course, index) => {
        let name = course[0]
        let desc = course[1]
        let link = course[2]
        return <>
            <tr key={`course_${index}`}>
                <th className={"about-me-app-content-education-table-name"} scope={"row"}><a target="_blank" href={link}>{name}</a></th>
                <td className={"about-me-app-content-education-table-desc"}>{desc}</td>
            </tr>
        </>
    }

    return (
        <>
            <table className={"about-me-app-content-education-table"}>
                <thead>
                    <tr>
                        <th className={"about-me-app-content-education-table-header"} scope={"col"}>Course</th>
                        <th className={"about-me-app-content-education-table-header-desc"} scope={"col"}>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {courses.map(parse_course)}
                </tbody>
            </table>
        </>
    )

}
export default AboutMeAppContentEductionCoursesTable;