import ToolbarApp from "./ToolbarApp/ToolbarApp";
import "./style.css";
import AboutMeAppPackage from "../../Apps/AboutMeApp/AboutMeApp.tsx";
import CanvasAppPackage from "../../Apps/CanvasApp/CanvasApp.tsx";
import GuessrAppPackage from "../../Apps/GuessrApp/GuessrAppController.tsx";
const Toolbar = () => {
  return (
    <>
      <div className={"toolbar"}>
        <div className={"toolbar_base"}></div>
        <div className={"toolbar_content"}>
          <ToolbarApp pkg={AboutMeAppPackage}></ToolbarApp>
          <ToolbarApp pkg={CanvasAppPackage}></ToolbarApp>
          <ToolbarApp pkg={GuessrAppPackage}></ToolbarApp>
        </div>
      </div>
    </>
  );
};
export default Toolbar;
