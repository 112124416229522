import "./style.css";
import React, { useContext, useEffect, useState } from "react";
import Draggable from "react-draggable";
const WindowRender = ({ window, onChange, index, saveWindow }) => {
  const [isActive, setIsActive] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  useEffect(() => {
    if (window.isActive) {
      setIsActive(true);
      setX(window.x);
      setY(window.y);
    }
  }, [window]);

  function closeWindow() {
    //setIsActive(false);
    onChange(window, index);
  }
  const handleStop = (event, dragElement) => {
    setX(dragElement.x);
    setY(dragElement.y);
    let newWindow = { ...window };
    newWindow.x = dragElement.x;
    newWindow.y = dragElement.y;
    saveWindow(newWindow, index);
  };

  const handleDrag = (event, dragElement) => {
    setX(dragElement.x);
    setY(dragElement.y);
  };

  const handleStart = (event, dragElement) => {
    setX(dragElement.x);
    setY(dragElement.y);
    let newWindow = { ...window };
    saveWindow(newWindow, index);
  };

  function renderWindow() {
    if (!isActive) {
      return <></>;
    }
    return (
      <Draggable
        handle={"strong"}
        onStop={handleStop}
        onStart={handleStart}
        onDrag={handleDrag}
        position={{ x: x, y: y }}
      >
        <div
          className={"window-render"}
          style={{
            height: window.height,
            width: window.width,
            zIndex: window.z,
          }}
        >
          <strong>
            <div className={"window-render-toolbar"}>
              <div className={"window-render-toolbar-title"}>
                {window.title}
              </div>
              <div
                className={"window-render-toolbar-close"}
                onClick={closeWindow}
              >
                ✕
              </div>
            </div>
          </strong>
          <div className={"window-render-content box no-cursor"}>
            {window.isActive && (
              <window.content
                service={window.service}
              />
            )}
          </div>
        </div>
      </Draggable>
    );
  }

  return <>{renderWindow()}</>;
};
export default React.memo(WindowRender);
