
// @ts-ignore
import icon from './icon.png'
import "./style.css"
import AboutMeAppTab from "./AboutMeAppTab";
import {useState} from "react";
import AboutMeAppContentMe from "./AboutMeAppContent/AboutMeAppContentMe";
import AboutMeAppContentEducation from "./AboutMeAppContent/AboutMeAppContentEducation";
import AboutMeAppContentWork from "./AboutMeAppContent/AboutMeAppContentWork";
import AboutMeAppContentProjects from "./AboutMeAppContent/AboutMeAppContentProjects";
import {IApplicationPackage} from "../../../models/application/application.model";
const AboutMeApp = () => {

    const [activeTab, setActiveTab] = useState("Me");

    return (
        <>
            <div className={"about-me-app"}>
                <div className={"about-me-app-tabs"}>
                    <AboutMeAppTab name={"Me"} activeTab={activeTab} setActiveTab = {setActiveTab}/>
                    <AboutMeAppTab name={"Education"} activeTab={activeTab} setActiveTab = {setActiveTab}/>
                    <AboutMeAppTab name={"Work"} activeTab={activeTab} setActiveTab = {setActiveTab}/>
                </div>
                <div className={"about-me-app-content"}>
                    {activeTab === "Me" && <AboutMeAppContentMe/>}
                    {activeTab === "Education" && <AboutMeAppContentEducation/>}
                    {activeTab === "Work" && <AboutMeAppContentWork/>}
                    {activeTab === "Projects" && <AboutMeAppContentProjects/>}
                </div>
            </div>
        </>
    )
}

const AboutMeAppPackage: IApplicationPackage = {
    windowsParams: [{
        title: "About Me",
        isActive: true,
        height: 500,
        width: 500,
        x: 100,
        y: 100,
        z: 1000,
        content: AboutMeApp
    }],
    icon: icon,
    label: "About Me"
}
export default AboutMeAppPackage


