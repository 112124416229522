import ClassicButton from "./NavButtons/ClassicButton";
import './style.css';
import TimeButton from "./NavButtons/TimeButton";
const Navbar = () => {
    return (
        <>
            <div className={"navbar"}>
                <ClassicButton text={"Mikhail Semyanovskiy"} link={"/"}></ClassicButton>
                <TimeButton></TimeButton>
            </div>
        </>
    )
}
export default Navbar;
