import "./style.css"
const AboutMeAppContentWork= () => {
    return (
        <>
            <div className={"about-me-app-content-work"}>
                <div className={"about-me-app-content-work-company"}>Thomson Reuters</div>
                <div className={"about-me-app-content-work-header"}>
                    <div className={"about-me-app-content-work-title"}>
                        Software Engineer Intern
                    </div>
                    <div className={"about-me-app-content-work-dates"}>June 2023 - June 2024</div>
                </div>
                <div className={"about-me-app-content-work-details"}>
                    <ul>
                        <li>Worked on corporate software solutions served on .NET and Angular.</li>
                        <li>Implemented and tested internal and public APIs.</li>
                        <li>Produced and presented POC proposals and roadmaps for infrastructure problems including event publishers and consumers. Deployed solutions in AWS.</li>
                        <li>Developed UI components in Angular framework using Typescript.</li>
                    </ul>
                </div>
            </div>
        </>
    )
}
export default AboutMeAppContentWork;