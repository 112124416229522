// @ts-ignore
import icon from "../AboutMeApp/icon.png";
import "./style.css";
import Canvas from "./Canvas";
import { useCallback, useEffect, useState } from "react";
import {
  IApplicationPackage,
  IWindowComponentProps,
} from "../../../models/application/application.model";

const CanvasApp = ({ windowX, windowY }: IWindowComponentProps) => {
  class Ball {
    public x: any;
    public y: any;
    public r: any;
    public vx: number;
    public vy: number;
    public staleColor: number;
    public pingColor: number;
    public currentColor: number;
    public rads: number = 0;
    constructor(
      x: number,
      y: number,
      r: number,
      vx: number,
      vy: number,
      staleColor = 0,
      pingColor = 16099935,
    ) {
      this.x = x;
      this.y = y;
      this.r = r;
      this.vx = 0.1;
      this.vx = 0.1;
      this.vx = vx;
      this.vy = vy;
      this.staleColor = staleColor;
      this.pingColor = pingColor;
      this.currentColor = staleColor;
    }
    updatePhys = (
      windowX: number,
      windowY: number,
      ctx: { canvas: { height: number; width: number } },
      stuff: {
        windowX: number;
        windowY: number;
      },
      pastX: number | undefined,
      pastY: number | undefined,
    ) => {
      if (pastX === undefined || pastY === undefined) {
        pastX = stuff.windowX;
        pastY = stuff.windowY;
      }

      if (this.currentColor >= this.pingColor / 10) {
        this.currentColor -= this.pingColor / 10;
      } else {
        this.currentColor = this.staleColor;
      }

      if (this.y + this.r < ctx.canvas.height && !(this.y - this.r < 0)) {
        //this.vy += 0.1;
        if (pastX !== stuff.windowX) {
          this.x -= stuff.windowX - pastX;
        }
      } else {
        if (this.vy > 0) this.vy = -this.vy * 0.95;

        // Friction on ground
        if (this.vx > 0) {
          this.vx -= 0.05;
        } else if (this.vx < 0) {
          this.vx += 0.05;
        }
        if (pastY !== stuff.windowY) {
          this.vy += (stuff.windowY - pastY) / 10;
        }
        if (pastX !== stuff.windowX) {
          this.rads -= (stuff.windowX - pastX) / 10;
          this.vx -= (stuff.windowX - pastX) / 10;
        }
      }
      if (
        (this.x + this.r >= ctx.canvas.width && this.vx > 0) ||
        (this.x - this.r <= 0 && this.vx < 0)
      ) {
        // Bounce
        this.vx = -this.vx * 0.95;
        if (pastX !== stuff.windowX) {
          this.vx += (stuff.windowX - pastX) / 10;
        }
      }

      this.y += this.vy;
      this.x += this.vx;
      //rads += wx;
    };
    draw = (
      ctx: {
        beginPath: () => void;
        fillStyle: string;
        arc: (
          arg0: any,
          arg1: any,
          arg2: any,
          arg3: number,
          arg4: number,
        ) => void;
        fill: () => void;
      },
      windowX: any,
      windowY: any,
    ) => {
      ctx.beginPath();
      ctx.fillStyle = "#" + this.currentColor.toString(16).padStart(6, "0");
      ctx.arc(this.x, this.y, this.r, 0, 2 * Math.PI);
      //ctx.fillText(`${windowX} ${windowY}`, 10, 50)
      //ctx.fillText(`${this.x} ${this.y}`, this.x, this.y)
      ctx.fill();
      // ctx.beginPath()
      // ctx.strokeStyle = ("#FF0000");
      // ctx.moveTo(this.x, this.y);
      // ctx.lineTo(this.x + this.r * Math.cos(this.rads), this.y + this.r * Math.sin(this.rads));
      // //ctx.arc(x, y, r, Math.PI, rads);
      // ctx.closePath()
      // ctx.stroke()
    };
  }
  let balls = [
    new Ball(100, 100, 35, 0, 0, 0, 16099935),
    new Ball(200, 200, 20, 0, 0, 0, 16099935),
    new Ball(300, 300, 30, 0, 0, 0, 16099935),
    new Ball(400, 400, 50, 0, 0, 0, 16099935),
    new Ball(500, 100, 30, 0, 0, 0, 16099935),
    new Ball(600, 200, 20, 0, 0, 0, 16099935),
    new Ball(700, 300, 50, 0, 0, 0, 16099935),
    new Ball(800, 400, 35, 0, 0, 0, 16099935),
  ];
  const [thisWindowX, setThisWindowX] = useState(windowX);
  const [thisWindowY, setThisWindowY] = useState(windowY);
  let pastX: number | undefined;
  let pastY: number | undefined;

  useEffect(() => {
    setThisWindowX(windowX);
    setThisWindowY(windowY);
    console.log(thisWindowX, thisWindowY);
  }, [windowX, windowY]);

  const draw = useCallback((ctx: any, frameCount: any, stuff: any) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.beginPath();
    for (let i = 0; i < balls.length; i++) {
      let circle = balls[i];
      circle.updatePhys(stuff.windowX, stuff.windowY, ctx, stuff, pastX, pastY);

      for (let j = i; j < balls.length; j++) {
        let circle2 = balls[j];
        let distance = Math.sqrt(
          Math.pow(circle.x - circle2.x, 2) + Math.pow(circle.y - circle2.y, 2),
        );

        if (distance === circle.r + circle2.r) {
          //circle.currentColor = circle.pingColor;
          //circle2.currentColor = circle2.pingColor;
          console.log("collide");
          let temp = circle.vx;
          circle.vx = circle2.vx;
          circle2.vx = temp;
          temp = circle.vy;
          circle.vy = circle2.vy;
          circle2.vy = temp;
        } else if (distance < circle.r + circle2.r) {
          //circle.currentColor = circle.pingColor;
          //circle2.currentColor = circle2.pingColor;
          //console.log("collide")

          ctx.lineWidth = 0.1;
          ctx.strokeStyle = "#ff0000";
          ctx.moveTo(circle.x, circle.y);
          ctx.lineTo(circle2.x, circle2.y);
          ctx.stroke();

          let theta = Math.atan2(circle2.y - circle.y, circle2.x - circle.x);
          let overlap = circle.r + circle2.r - distance;
          circle.x -= overlap * Math.cos(theta);
          circle.y -= overlap * Math.sin(theta);
          circle2.x += overlap * Math.cos(theta);
          circle2.y += overlap * Math.sin(theta);
          let temp = circle.vx;
          circle.vx = circle2.vx;
          circle2.vx = temp;
          temp = circle.vy;
          circle.vy = circle2.vy;
          circle2.vy = temp;
        } else {
          ctx.lineWidth = 0.1;
          ctx.strokeStyle = "#FFFFFF";
          ctx.moveTo(circle.x, circle.y);
          ctx.lineTo(circle2.x, circle2.y);
          ctx.stroke();
        }
      }
    }

    if (pastX !== stuff.windowX || pastY !== stuff.windowY) {
      pastX = stuff.windowX;
      pastY = stuff.windowY;
    }
    for (let i = 0; i < balls.length; i++) {
      balls[i].draw(ctx, stuff.windowX, stuff.windowY);
    }
  }, []);

  return (
    <div className={"canvas-app"}>
      <Canvas
        draw={draw}
        stuff={{ windowX: thisWindowX, windowY: thisWindowY }}
        options={{}}
      >
        {" "}
      </Canvas>
    </div>
  );
};

const CanvasAppPackage: IApplicationPackage = {
  windowsParams: [
    {
      title: "Canvas",
      isActive: true,
      height: 500,
      width: 500,
      x: 100,
      y: 100,
      z: 1000,
      content: CanvasApp,
    },
  ],
  icon: icon,
  label: "Canvas",
};
export default CanvasAppPackage;
