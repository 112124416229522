import "./style.css"
import AboutMeAppContentEductionCoursesTable from "./AboutMeAppContentEductionCoursesTable";
const AboutMeAppContentEducation = () => {

    let cs_courses_taken = [
        ["CSC384", "Introduction to Artificial Intelligence", "https://artsci.calendar.utoronto.ca/course/csc384h1"],
        ["CSC311", "Introduction to Machine Learning", "https://artsci.calendar.utoronto.ca/course/csc311h1"],
        ["CSC369", "Operating Systems", "https://artsci.calendar.utoronto.ca/course/csc369h1"],
        ["CSC373", "Algorithm Design and Analysis", "https://artsci.calendar.utoronto.ca/course/csc373h1"],
        ["CSC309", "Programming on the Web", "https://artsci.calendar.utoronto.ca/course/csc309h1"],
        ["CSC343", "Introduction to Databases", "https://artsci.calendar.utoronto.ca/course/csc343h1"],
        ["CSC358", "Principles of Computer Networking", "https://utm.calendar.utoronto.ca/course/csc358h5"],
        ["CSC263", "Data Structures and Analysis", "https://utm.calendar.utoronto.ca/course/csc263h5"],
        ["CSC258", "Computer Organization", "https://utm.calendar.utoronto.ca/course/csc258h5"],
        ["CSC209", "Software Tools and Systems Programming", "https://utm.calendar.utoronto.ca/course/csc209h5"],
        ["CSC236", "Introduction to the Theory of Computation", "https://utm.calendar.utoronto.ca/course/csc236h5"],
        ["CSC207", "Software Design", "https://utm.calendar.utoronto.ca/course/csc207h5"],
        ["CSC148", "Introduction to Computer Science", "https://utm.calendar.utoronto.ca/course/csc148h5"],
        ["CSC108", "Introduction to Computer Programming", "https://utm.calendar.utoronto.ca/course/csc108h5"],
    ]

    return (
        <>
            <div className={"about-me-app-content-education"}>
                <div className={"about-me-app-content-education-university"}>
                    <div className={"about-me-app-content-education-university-title"}>
                        University of Toronto 🇨🇦
                    </div>
                    <div className={"about-me-app-content-education-university-header"}>
                        <div className={"about-me-app-content-education-university-spec"}>
                            - 4<sup>th</sup> year HBSc Computer Science Specialist · PEY co-op
                        </div>
                    </div>
                    <AboutMeAppContentEductionCoursesTable courses={cs_courses_taken}></AboutMeAppContentEductionCoursesTable>
                </div>
                <div className={"about-me-app-content-education-hs"}>

                </div>
            </div>
        </>
    )
}
export default AboutMeAppContentEducation;