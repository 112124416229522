import { useRef, useEffect } from 'react'

const useCanvas = (draw, options={}) => {

    const canvasRef = useRef(null)

    function resizeCanvasToDisplaySize(canvas) {

        const { width, height } = canvas.parentElement.getBoundingClientRect()

        if (canvas.width !== width || canvas.height !== height) {
            canvas.width = width
            canvas.height = height
            return true // here you can return some usefull information like delta width and delta height instead of just true
            // this information can be used in the next redraw...
        }

        return false
    }

    function resizeCanvas(canvas) {
        const { width, height } = canvas.parentElement.getBoundingClientRect()

        if (canvas.width !== width || canvas.height !== height) {
            const { devicePixelRatio:ratio=1 } = window
            const context = canvas.getContext('2d')
            canvas.width = width*ratio
            canvas.height = height*ratio
            context.scale(ratio, ratio)
            return true
        }

        return false
    }

    useEffect(() => {
        const canvas = canvasRef.current
        resizeCanvasToDisplaySize(canvas);
        const context = canvas.getContext(options?.context || '2d')
        let frameCount = 0
        let animationFrameId
        const render = () => {
            if (frameCount !== 0) {
                draw(context, frameCount, options?.stuff)
            }
            frameCount++
            animationFrameId = window.requestAnimationFrame(render)
        }
        render()
        return () => {
            window.cancelAnimationFrame(animationFrameId)
        }
    }, [draw, options?.stuff])
    return canvasRef
}
export default useCanvas
