import React from 'react'
import useCanvas from './useCanvas'

const Canvas = props => {

    const { draw, options, stuff, ...rest } = props
    const { context, ...moreConfig } = options
    const canvasRef = useCanvas(draw, {context, stuff})

    return <canvas ref={canvasRef} {...rest}/>
}

export default Canvas
