import {useEffect, useState} from "react";
import "./style.css";

const TimeButton = () => {

    const [time, setTime] = useState("");
    useEffect(() => {
        getTime();
    }, []);
    function getTime() {
        let date = new Date();
        let hour = date.getHours();
        let appendix;
        if (hour > 12) {
            hour -= 12;
            appendix = "PM";
        }
        else {
            appendix = "AM"
        }

        let minute = date.getMinutes();
        setTime(hour + ":" + minute + " " + appendix);
        setTimeout(getTime, 1000);
    }

    return (
        <div className={"time-button"}>
            {time}
        </div>
    )
}
export default TimeButton;