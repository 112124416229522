import {useNavigate} from "react-router-dom";
import './style.css'
const ClassicButton = ({text, link}) => {

    const navigate = useNavigate();

    return (
        <>
        <div className={"nav-classic-button"} onClick={(event) => {navigate(link)}}>
            {text}
        </div>
        </>
    )

}
export default ClassicButton;
