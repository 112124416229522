import {
  GoogleMap,
  StreetViewPanorama,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import {
  IGuessrLocation,
  IGuessrProps,
} from "../../../../models/application/GuessrApplication/guessr-application.model";
import "./styles.css";
const containerStyle = {
  width: "1000px",
  height: "1000px",
};



const GuessrAppMap = ({ service, ...props }: IGuessrProps) => {
  const [map, setMap] = useState<google.maps.Map>();
  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    // On Map Load
    setMap(map);
  }, []);
  console.log("MAP RELOAD")
  //let chosenPositionTemp: {x: number, y: number} | null = null
  const [chosenPositionTemp, setChosenPositionTemp] = useState<{lat: number, lng: number}>();
  const controller = service.getGuessrController();
  const [choosingPosition, setChoosingPosition] = useState<boolean>(true);
  const [chosenPosition, setChosenPosition] = useState<IGuessrLocation>();
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [secretMarker, setSecretMarker] = useState<google.maps.Marker>();

  controller.register("map", setChosenPosition);

  useEffect(() => {     // When user selects a position
    if (map && chosenPosition) {
      let line = new window.google.maps.Polyline({
        path: [controller.secret_location, chosenPosition],
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      line.setMap(map);
      setSecretMarker( new window.google.maps.Marker({
        position: controller.secret_location,
        map
      }));
    }
  }, [chosenPosition]);

  useEffect(() => {
    if (map && marker) {
      marker.setMap(map);
    }
    if (map && secretMarker) {
      secretMarker.setMap(map);
      const bounds = new window.google.maps.LatLngBounds(
          controller.secret_location,
      );
      const bounds2 = new window.google.maps.LatLngBounds(
          chosenPosition,
      );
      bounds.union(bounds2)
      map.fitBounds(bounds);
    }
  }, [marker, secretMarker]);

  useEffect(() => {
    if (chosenPositionTemp) {
      controller.setChosenLocation(chosenPositionTemp.lat, chosenPositionTemp.lng);
    }
  }, [choosingPosition]);

  const onLoadPanorama = (
    streetViewPanorama: google.maps.StreetViewPanorama,
  ) => {
    streetViewPanorama.setPosition(controller.secret_location);
    streetViewPanorama.setVisible(true);
  };

  const onClick = (e: any) => {
    if (chosenPosition && !choosingPosition) {
      return;
    }
    if (marker) {
      marker.setMap(null);
    }
    setMarker(new window.google.maps.Marker({
      position: {lat: e.latLng.lat(), lng: e.latLng.lng()},
      map
    }));
    setChosenPositionTemp({lat: e.latLng.lat(), lng: e.latLng.lng()});
  };

  // @ts-ignore
  return  (
    <>
      <div className={"guessr-app"}>
        {(chosenPositionTemp && choosingPosition) ? (<div onClick={() => setChoosingPosition(false)} className={"guessr-app-interaction"}>
          Confirm
        </div>) : <> </>}
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={{ clickableIcons: false, disableDefaultUI: true }}
          center={{ lat: 0, lng: 0 }}
          zoom={1}
          onClick={onClick}
          onLoad={onLoad}
        >
          <StreetViewPanorama
            onLoad={onLoadPanorama}
            options={{
              addressControl: false,
              disableDefaultUI: true,
              enableCloseButton: true,
              linksControl: false,
              showRoadLabels: false,
              imageDateControl: false,
            }}
          />
        </GoogleMap>
      </div>
    </>
  )
};
export default React.memo(GuessrAppMap);
