import "./style.css";
import { useContext } from "react";

import {
  IToolbarApplication,
  IWindowContext,
  IWindowParameters,
} from "../../../../models/application/application.model";
import { WindowsContext } from "../../Layout";
const ToolbarApp: IToolbarApplication = ({ pkg }) => {
  const { windows, setWindows } = useContext<IWindowContext>(WindowsContext);
  function generateUniqSerial(): string {
    return "xxxx-xxxx-xxx-xxxx".replace(/[x]/g, (c) => {
      const r = Math.floor(Math.random() * 16);
      return r.toString(16);
    });
  }
  function addWindow() {
    let windows_params: IWindowParameters[] = [];
    for (let j = 0; j < pkg.windowsParams.length; j++) {
      let window_params = { ...pkg.windowsParams[j] };

      for (let i = 0; i < windows.length; i++) {
        if (
          windows[i].x === window_params.x ||
          windows[i].y === window_params.y
        ) {
          window_params.x += 15;
          window_params.y += 15;
        }
        if (windows[i].z === window_params.z) {
          window_params.z += 1;
        }
      }
      window_params.id = generateUniqSerial();
      windows_params.push(window_params);
    }
    setWindows([...windows, ...windows_params]);
  }

  return (
    <>
      <div className={"toolbar-app"}>
        <div className={"toolbar-label"}>{pkg.label}</div>
        <div className={"toolbar-icon"}>
          <img src={pkg.icon} alt={pkg.label} onClick={addWindow} />
        </div>
      </div>
    </>
  );
};

export default ToolbarApp;
