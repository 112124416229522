import WindowRender from "./WindowRender";
import { WindowsContext } from "../../Layout/Layout";
import { useCallback, useContext } from "react";
import { IWindowParameters } from "../../../models/application/application.model";

const Home = () => {
  const { windows, setWindows } = useContext(WindowsContext);
  const closeWindow = (window: IWindowParameters, index: number) => {
    setWindows(windows.filter((w, i) => i !== index));
  };

  const saveWindow = (window: IWindowParameters, index: number) => {
    // Update windows z-index
    return;
    let max = 1000;
    for (let i = 0; i < windows.length; i++) {
      console.log(windows[i].z);
      if (windows[i].z > max) {
        max = windows[i].z;
      }
    }
    window.z = window.z !== max ? max + 1 : window.z;
    setWindows((prevWindows: IWindowParameters[]) =>
      prevWindows.map((w: IWindowParameters, i: number) => {
        return i === index ? window : w;
      }),
    );
  };

  return (
    <>
      <div>
        {windows.map((window, index) => (
          <WindowRender
            window={window}
            index={index}
            onChange={closeWindow}
            saveWindow={saveWindow}
            key={`window_${window.id}`}
          ></WindowRender>
        ))}
      </div>
    </>
  );
};
export default Home;
