// @ts-ignore
import { IApplicationPackage } from "../../../models/application/application.model";
import GuessrAppMap from "./GuessrAppMap/GuessrAppMap";
import GuessrAppPopup from "./GuessrAppPopup/GuessrAppPopup";
// @ts-ignore
import icon from "./icon.png";
import { GuessrService } from "../../../services/applications/Guessr/guessr.service";

const guessrService = new GuessrService();

const GuessrAppPackage: IApplicationPackage = {
  windowsParams: [
    {
      title: "Guessr",
      isActive: true,
      height: 1000,
      width: 1000,
      x: 100,
      y: 100,
      z: 1000,
      content: GuessrAppMap,
      service: guessrService,
    },
    {
      title: "Guessr Info",
      isActive: true,
      height: 400,
      width: 300,
      x: 600,
      y: 100,
      z: 1001,
      content: GuessrAppPopup,
      service: guessrService,
    },
  ],
  icon: icon,
  label: "Guessr",
};
export default GuessrAppPackage;
