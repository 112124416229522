import { Controller, Service } from "../shared/shared.service";
import { IGuessrLocation } from "../../../models/application/GuessrApplication/guessr-application.model";
import { Dispatch, SetStateAction, useState } from "react";
import { forEach } from "@react-google-maps/api/dist/utils/foreach";
import {
  IGuessrApplicationControllerExchange
} from "../../../models/application/GuessrApplication/guessr-controller.model";

export class GuessrService implements Service {
  private _guessrController: GuessrController | undefined;

  constructor() {}

  getGuessrController(): GuessrController {
    if (!this._guessrController) {
      this._guessrController = new GuessrController();
    }
    return this._guessrController;
  }
}

export class GuessrController {
  private locations: IGuessrLocation[] = [
    {
      lat: 55.739493276443426,
      lng: 37.500028281906694,
    },
  ];
  public secret_location: IGuessrLocation;
  public chosen_location: IGuessrLocation | null = null;
  private guessr_components: { [index: string]: any } = {};

  constructor() {
    this.secret_location = this.locations[0];
  }

  public register(
    name: string,
    obj: any,
  ) {
    this.guessr_components[name] = obj;
  }
  public setChosenLocation(lat: number, lng: number) {
    this.chosen_location = {
      lat: lat,
      lng: lng,
    };
    for (const [key, setter] of Object.entries(this.guessr_components)) {
      setter({ lat: lat, lng: lng });
    }
  }

  public resetGame() {
    this.chosen_location = null;
  }
}
