import "./styles.css";
import {
  IGuessrLocation,
  IGuessrProps,
} from "../../../../models/application/GuessrApplication/guessr-application.model";
import { useEffect, useState } from "react";
const GuessrAppPopup = ({ service }: IGuessrProps) => {
  const controller = service.getGuessrController();
  const [chosenPosition, setChosenPosition] = useState<IGuessrLocation>();
  const [msg, setMsg] = useState("");
  controller.register("info", setChosenPosition);
  function getDistanceFromLatLonInKm(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
  ) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d.toFixed(1);
  }

  function deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }
  useEffect(() => {
    let secret = controller.secret_location;
    if (chosenPosition)
      setMsg(
        `${getDistanceFromLatLonInKm(chosenPosition.lat, chosenPosition.lng, secret.lat, secret.lng)}`,
      );
  }, [chosenPosition]);

  return (
    <>
      <div className={"guessr-app-popup"}>
        <div className={"guessr-app-popup-msg"}>Distance is: {msg} kilometers</div>
        </div>
    </>
  );
};
export default GuessrAppPopup;
