import "./style.css"

const AboutMeAppContentMe = () => {

    function getAge() {
        let currentDate = new Date();
        let birth = new Date(2002, 5, 2);

        let diff = currentDate - birth;
        return Math.floor((currentDate - birth) / (1000*60*60*24*365))

    }

    return (
        <>
            <div className={"about-me-app-content-me"}>
                <div className={"about-me-app-content-me-name"}>Mikhail Semyanovskiy</div>
                <div className={"about-me-app-content-me-info"}>🇨🇦 M, {getAge()} y.o.</div>
                <div className={"about-me-app-content-me-socials"}>
                    <ul className={"about-me-app-content-me-socials-list"}>
                        <li><i className="fa-brands fa-github"></i> <a target="_blank" href={"https://github.com/ijdbuser"}>Github</a></li>
                        <li><i className="fa-brands fa-linkedin"></i> <a target="_blank" href={"https://www.linkedin.com/in/msemyanovsky/"}>LinkedIn</a></li>
                        <li><i className="fa-brands fa-instagram"></i> <a target="_blank" href={"https://www.instagram.com/misherton/"}> Instagram</a></li>
                        <li><i className="fa-brands fa-discord"></i> Discord - misha2430</li>
                    </ul>
                </div>
            </div>
        </>
    )
}
export default AboutMeAppContentMe;