import { Outlet } from "react-router-dom";
import "./style.css";
import Navbar from "./Navbar";
import Toolbar from "./Toolbar";
import { createContext, useState } from "react";
import {
  IWindowContext,
  IWindowParameters,
} from "../../models/application/application.model";

export const WindowsContext = createContext<IWindowContext>({
  windows: [],
  setWindows: null,
});

const Layout = () => {
  const [windows, setWindows] = useState<IWindowParameters[]>([]);

  return (
    <>
      <WindowsContext.Provider value={{ windows, setWindows }}>
        <div className={"page"}>
          <Navbar />
          <div className={"page-content"}>
            <Outlet />
          </div>
          <Toolbar></Toolbar>
        </div>
      </WindowsContext.Provider>
    </>
  );
};
export default Layout;
