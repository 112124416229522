import "./style.css"
import {useEffect, useState} from "react";
const AboutMeAppTab = ({name, activeTab, setActiveTab}) => {
    const [isActive, setIsActive] = useState(false);
    useEffect(
        () => {
            setIsActive(activeTab === name);
        }
        , [activeTab]);

    return (
        <>
            <div className={isActive ? "about-me-app-tab-active" : "about-me-app-tab"} onClick={() => setActiveTab(name)}>
                {name}
            </div>
        </>
    )
}
export default AboutMeAppTab;